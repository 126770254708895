.container {
    flex: 1;
}

.main {
    margin: 0 10px 0 10px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main > div[container] {
    flex-grow: 1;    
}

header {
    display: flex;
    align-items: center;
    position: sticky;
    background-color: white;
    color: black;
    border-radius: 0 0 7px 7px;
}

.logo {
    position: absolute;
    left: 10px;
    animation: spin 4s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}

@keyframes shadow-appear {
    0% {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        text-shadow: -2px -2px rgba(0, 0, 0, 0.5);
    }
}

.logo-text {
    font-family: serif;
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    flex: 1;
    text-align: center;
    margin: 20px 0 20px 0;
    font-size: 35px;
    font-weight: bold;
    animation: shadow-appear 1.5s ease-in-out forwards;
    padding-left: 150px;
}

@media (max-width: 768px) {
    .container {
      padding: 10px;
    }

}

/* header */

.padding-right-5px {
    padding-right: 5px;
}

.padding-right-last {
    padding-right: 15px;
}

.number {
    padding-left: 20px;
    /* padding-right: 100px; */
    /* margin-right: 100px; */
}

/* 1st section */
.first-section {
    text-align: center;
    background-image: url('../../../public/images/af173db5-9a93-4f9d-9ad3-43d145cddc2f.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    /* height: 100vh; */
    overflow-y: auto;
    color: white;
    /* font-size: 1em; */
    text-shadow: 
    -1px -1px 0 black,  
     1px -1px 0 black,
    -1px  1px 0 black,
     1px  1px 0 black;
}

.typing-text {
    font-family: monospace;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    animation: typing 3s steps(30, end) forwards, blink-caret 0.5s step-end infinite;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    50% {
        border-color: transparent;
    }
}


.copied-message {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    opacity: 0;
    animation: fadeOut 4s forwards;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  

/* Mobile view */
@media (max-width: 768px) {
    .logo-text {
        padding-left: 50px;
        text-align: center;
    }
}

.services {
    margin: 20px 0 20px 0;
}

/* Footer stays at the bottom with margin auto adjustment */
footer {
    position: sticky;
    /* bottom: 0; */
    text-align: center;
    color: black;
    padding: 10px;
    background-color: white;
    margin-top: auto;
    border-radius: 7px 7px 0 0;
}

/* .copyright {
    text-align: center;
    margin: 20px 0;
} */
