.icon-text-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns */
  gap: 20px; /* Space between grid items */
  max-width: 600px;
  margin: 0 auto;
}
  
.icon-text-item {
  display: flex;
  align-items: center;
  gap: 15px; /* Space between icon and text */
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.5s forwards;
}
  
/* Add a slight delay for each item to appear in sequence */
.icon-text-item:nth-child(1) { animation-delay: 0.2s; }
.icon-text-item:nth-child(2) { animation-delay: 0.4s; }
.icon-text-item:nth-child(3) { animation-delay: 0.6s; }
.icon-text-item:nth-child(4) { animation-delay: 0.8s; }
  
/* Animation to fade in and move up */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
  
.icon-text-item p {
  margin: 0;
  font-size: 1rem;
  color: #333;
}
  
@media (max-width: 768px) {
  .icon-text-grid {
    grid-template-columns: 1fr;
  }
}